import "./App.css";
import { useState } from "react";
import One from "./components/One";
import Two from "./components/Two";
import Three from "./components/Three";

function App() {
  const [view, setView] = useState(1);
  function changeView(view) {
    setView(view);
  }
  return (
    <div className="App">
      <div className="bg-white m-3 p-3 rounded-lg shadow-md flex flex-col border items-center h-[400px]">
        {view === 1 ? (
          <One onNext={() => changeView(2)}></One>
        ) : view === 2 ? (
          <Two
            onNext={() => changeView(3)}
            onPrevious={() => changeView(1)}
          ></Two>
        ) : (
          <Three onPrevious={() => changeView(2)}></Three>
        )}
      </div>
    </div>
  );
}

export default App;
