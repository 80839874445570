import React from "react";

function Two(props) {
  function handleCopy() {
    navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
      if (result.state === "granted" || result.state === "prompt") {
        /* write to the clipboard now */
        navigator.clipboard.writeText("026 305 32 22");
      }
    });
  }
  return (
    <div className="flex flex-col h-full items-center">
      <p className="text-left font-medium text-sm mb-3">
        Contacter le service informatique de l'Etat de Fribourg
      </p>
      <p className="text-left text-sm mb-3">
        Si malgré différentes recherches, votre problème n'est pas résolu, vous
        pouvez contacter le Service Informatique cantonal (SITEL).
      </p>
      <a
        href="http://serviceclient.fr.ch/"
        target="_blank"
        className="bg-rose-600 text-white rounded-md px-3 py-1 my-3 flex items-center"
      >
        Ouvrir un ticket{"  "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-4 h-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
          />
        </svg>
      </a>
      <div className="flex ">
        <div className="bg-gray-100 font-semibold text-gray-600 px-3 py-1 rounded-l-md border-y border-l border-gray-400">
          026 305 32 22
        </div>
        <div
          className="border border-gray-400 rounded-r-md px-3 flex items-center cursor-pointer"
          onClick={() => handleCopy()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
            />
          </svg>
        </div>
      </div>
      <div className="grow"></div>
      <div className="flex w-12 justify-between my-6">
        <div className="rounded-full bg-rose-200 h-2 w-2"></div>
        <div className="rounded-full bg-rose-600 h-2 w-2"></div>
        <div className="rounded-full bg-rose-200 h-2 w-2"></div>
      </div>
      <div className="flex w-full">
        <button
          className="border border-gray-300 text-gray-800 font-semibold px-3 text-sm py-1 w-1/2 rounded-lg"
          onClick={props.onPrevious}
        >
          Retour
        </button>
        <button
          className="bg-gray-500 font-semibold text-white px-3 py-1 w-1/2 rounded-lg text-sm ml-2 border borde-gray-500"
          onClick={props.onNext}
        >
          Toujours pas résolu
        </button>
      </div>
    </div>
  );
}

export default Two;
