import React from "react";

function Three(props) {
  return (
    <div className="flex flex-col h-full w-full">
      <p className="text-left font-medium text-sm mb-3">
        Contacter le service informatique de Grangeneuve
      </p>
      <p className="text-sm text-left text-gray-400 mb-5">
        Si vous n'avez pas pu résoudre votre problème avec le service client,
        vous pouvez contacter le service informatique directement.
      </p>
      <a
        href="mailto:olivier.guignard@fr.ch"
        className="flex w-full items-center self-start mb-3 hover:bg-gray-50 rounded-md cursor-pointer"
      >
        <div className="bg-green-200 h-8 w-8 rounded-full mr-3"></div>
        <div className="flex flex-col items-start">
          <span className="text-sm font-semibold">Loïc Folly</span>
          <span className="text-sm text-gray-400">
            Responsable informatique
          </span>
        </div>
      </a>

      <a
        href="mailto:olivier.guignard@fr.ch"
        className="flex w-full items-center self-start mb-3 hover:bg-gray-50 rounded-md cursor-pointer"
      >
        <div className="bg-amber-200 h-8 w-8 rounded-full mr-3"></div>
        <div className="flex flex-col items-start">
          <span className="text-sm font-semibold">Diego Epiney</span>
          <span className="text-sm text-gray-400">Technicien TIC SITEL</span>
        </div>
      </a>
      <a
        href="mailto:olivier.guignard@fr.ch"
        className="flex w-full items-center self-start mb-3 hover:bg-gray-50 rounded-md cursor-pointer"
      >
        <div className="bg-purple-200 h-8 w-8 rounded-full mr-3"></div>
        <div className="flex flex-col items-start">
          <span className="text-sm font-semibold">Olivier Guignard</span>
          <span className="text-sm text-gray-400">Stagiaire informatique</span>
        </div>
      </a>
      <div className="grow"></div>
      <div className="flex w-12 justify-between my-6 self-center">
        <div className="rounded-full bg-rose-200 h-2 w-2"></div>
        <div className="rounded-full bg-rose-200 h-2 w-2"></div>
        <div className="rounded-full bg-rose-600 h-2 w-2"></div>
      </div>
      <div className="flex w-full">
        <button
          className="border border-gray-300 text-gray-800 font-semibold px-3 text-sm py-1 w-1/2 rounded-lg mr-2"
          onClick={props.onPrevious}
        >
          Retour
        </button>
      </div>
    </div>
  );
}

export default Three;
