import React from "react";

function One(props) {
  return (
    <div className="flex flex-col justify-start h-full">
      <div>
        <p className="text-left font-medium text-sm mb-3">
          Chercher parmi les nombreuses ressources à votre disposition
        </p>
        <p className="text-left text-sm">
          Avant toute chose, faire une recherche sur Google. En effet, le moteur
          de recherche regorge d'informations, d'aide et de tutoriels en tout
          genre.
        </p>
        <div className="">
          <a
            href="https://www.google.ch"
            target="_blank"
            className="text-rose-600 underline font-semibold text-sm flex items-center"
          >
            Rechercher sur Google{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
              />
            </svg>
          </a>
        </div>
        <p className="text-left text-sm mt-6">
          Vous trouverez aussi beaucoup de réponses dans la rubrique Tutos du
          service informatique.
        </p>
        <div className="self-start">
          <a
            href="https://www.google.ch"
            target="_blank"
            className="text-rose-600 underline font-semibold text-sm flex items-center"
          >
            Aller vers les tutos{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
              />
            </svg>
          </a>
        </div>
      </div>
      <div className="grow"></div>
      <div className="flex w-12 justify-between self-center my-6">
        <div className="rounded-full bg-rose-600 h-2 w-2"></div>
        <div className="rounded-full bg-rose-200 h-2 w-2"></div>
        <div className="rounded-full bg-rose-200 h-2 w-2"></div>
      </div>
      <div className="flex w-full justify-end">
        <div className="flex justify-end w-1/2">
          <button
            className="bg-gray-500 font-semibold text-white px-3 py-1 w-full rounded-lg ml-2 text-sm border borde-gray-500"
            onClick={props.onNext}
          >
            Je ne trouve pas
          </button>
        </div>
      </div>
    </div>
  );
}

export default One;
